
.loginTips {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;

    a {
        color: #003fe6;
    }
}

@keyframes fadeIn {
    0% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        display: block;
        opacity: 1;
    }

    99% {
        display: block;
    }

    100% {
        display: none;
    }
}

p{
    margin: 0;
}
.submits{
    background:#15348E !important;
}
.sends{
    :global{
        .send{
            background:#15348E !important;
        }
        .disabled {
            background: #d8d8d8 !important;
            pointer-events: none;
        }
    }
}
.form{
    // width: auto !important;
    // height: 597px !important;
    // margin: 0 auto;
    margin-top:15px;
    :global{
        .tab-menu {
            line-height: 1.5;
            font-size: 18px;
            // color: #333;
            font-weight: 400;
            margin-bottom: 10px;
            padding: 10px 0;
            cursor: pointer;
        }
        .active {
            // background: RGBA(196, 211, 255, 0.5);
            color: #2e4a9d;
            border-bottom: 0.1388vw solid #2e4a9d;
        }
        .form-group{
            margin: 20px auto;
            .register-mobile{
                border: 1px solid #dbdfe7;
                input{
                    border: none!important;
                    outline: none;
                }
            }
            >div{
                button{
                    padding: 1px 6px;
                    background:#15348e;
                }

                .send.disabled {
                    background: #d8d8d8;
                    pointer-events: none;
                }
            }
            .dropdown{
                .active {
                    background:white;

                }
            }
            .submit{
                background:#15348e;
                color: white;
            }
        }
        .form-group-button{
            margin-bottom: 20px;
        }
    }
}
.register_form::before {
    content: none;
}
.registerSuccessDialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);
    z-index: 1000;
    display: none;

    &.active {
        animation: fadeIn .3s;
        display: block;
    }

    .dialog_content {
        position: absolute;
        // display: flex;
        background: url(../../assets/images/registe-bj-pc.png) no-repeat center/cover;
        top: 10%;
        left: 50%;
        margin-left: -480px;
        width: 960px;
        height: 626px;
        border-radius: 21px;
        z-index: 1000;
        box-shadow: 0px 16px 32px rgba(0, 0, 0, .3);
        overflow: hidden;
        :global{
            .download{
                display: none;
            }
        }
        

        .rsd_left {
            // width: 476px;
            text-align: center;
            p{
                font-size: 40px;
                font-weight: bold;
                color: #142D58;
            }
            :global{
                .rsd_left_div1{
                    width: 147px;
                    height: 147px;
                    margin: 0 auto;
                    background: url(../../assets/images/gold.png) no-repeat center/cover;
                    margin-top: 64px;
                }
            }
            .rsd_x{
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                img{
                    width: 40.2px;
                    height: 40.2px;
                }
            }
        }

        .rsd_right {
            flex: 1;
            // padding-top: 150px;
            // background: #102648;
            span{
                color: #FFA200;
                font-weight: bold;
            }

            .icon {
                margin: 0 auto 21px;
                width: 68px;
                height: 68px;

                img {
                    width: 100%;
                    height: 100%;
                    vertical-align: top;
                }
            }

            .p1 {
                margin-bottom: 12px;
                font-size: 26px;
                font-weight: bold;
                color: #0E2463;
                line-height: 42px;
                text-align: center;
                font-weight: 400;
            }

            .p2 {
                width: 43%;
                margin: 0 auto;
                margin-top: 50px;
                background: #15348E;
                border-radius: 10px;
                font-size: 22px;
                color: #fff;
                padding: 20px 35px;
                text-align: center;
            }
            .p3{
                text-align: center;
                font-size: 58px;
                color: #0E2463;
                font-weight: bold;
            }
            .phone {
                display: none;
            }

            .buttons {
                display: none;
            }
        }
    }
}

.searchbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
        flex-grow: 1;
        border: 1px solid #ddd !important;
        height: 36px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 1080px) {
    .form{
        width: 88vw !important;
        height: auto !important;
        margin-top: 0.8vw;
        :global{
            .tab-menu {
                width: 40.667vw;
                height: 10.4vw;
                font-size: 3.733vw;
                margin-bottom: 10px;
                padding: 10px 0;
            }
            .form-group{
                margin: 5px auto;
            }
        }
    }
    .registerSuccessDialog {
        .dialog_content_signup_bonus2{
            background: url(../../assets/images/registe-bj-pc.png) no-repeat center/cover !important;
        }
        .dialog_content {
            background: url(../../assets/images/registe-bj-phone.png) no-repeat center/cover;
            top: 123px;
            margin-left: -167.5px;
            width: 80vw;
            height: 86.667vw;
            flex-direction: column;
            margin: 0 auto;
            position: static;
            margin-top: 50%;
            overflow: visible;
            display: block;
            border-radius: 2.8vw;
            :global{
                .download{
                    width: 80%;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    margin-top: 5vw;
                    img{
                        width:29.333vw;
                        height: 10vw;
                    }
                }
            }
 

            .rsd_left_signup_bonus2{
                background: none !important;
                :global{
                    .rsd_left_div1{
                        width: 17.067vw;
                        height: 17.067vw;
                        margin-top: 11.4vw;
                       display:block !important;
                    }
                    p{
                        // color: #2E4A9D !important;
                        bottom: -6.063vw !important;
                    }
                }
            }
            .rsd_left {
                width: 56.267vw;
                height: 29.8vw;
                background: url(../../assets/images/registe-success.png) no-repeat center/cover;
                // background-size: 100%;
                margin: 0 auto;
                // margin-top:-6.667vw;
                position: relative;
                top:-20px;
                display: flex;
                justify-content: center;
                p{
                    margin: 0;
                    position: absolute;
                    // left: 19%;
                    bottom: 3.937vw;
                    font-size:5.867vw;
                    width:60.133vw;
                    text-align: center;
                    color: #0E2463;
                    
                }
                :global(.rsd_left_p){
                    line-height: 20px;
                }
                :global{
                    .rsd_left_div1{
                       display:none;
                    }
                }
                .rsd_x{
                    top: 6.2vw;
                    right: -10.8vw;
                    img{
                        width: 5.467vw;
                        height: 5.467vw;
                    }
                }
            }
           
            .rsd_right_signup_bonus2{
                
                    .p3{
                        font-size: 10.333vw;
                    }
                    .p2{
                        a{
                            display: inline-block;
                            // width: 44vw;
                            padding: 0 1vw;
                            height: 10.667vw;
                            line-height:10.667vw;
                            color:white;
                            background: #15348E;
                            border-radius: 1.333vw;
                            font-size:4.8vw;
                            margin-top: 2vw;
                        }
                    }
                
                
            }
            .rsd_right {
                width: 100%;
                padding: 0px 0;
                background: none;
                // margin-top: 5.2vw;
                .icon {
                    margin-bottom: 16px;
                    width: 28px;
                    height: 28px;
                }

                .p1 {
                    margin-bottom: 3px;
                    font-size: 4.4vw;
                    line-height: 1.5;
                    color: #0E2463;
                    font-weight: 500;
                    margin-top: 2vw;
                    font-weight: 400;
                    b{
                        font-weight: 600;
                    }
                }
                .p2 {
                    margin-bottom: 2.133vw;
                    font-size: 3.467vw;
                    line-height: 4.933vw;
                    margin-top: 2.667vw;
                    color: #000;
                    width:auto; 
                    /* margin: 0 auto; */
                     background: none; 
                     padding: 0; 
                     text-align: center;
                     a{
                        width: 72vw;
                     }
                }

                .phone {
                    display: block;
                }

                .pc {
                    display: none;
                }

                .buttons {
                    display: flex;
                    justify-content: center;
                    margin-top: 16%;

                    a {
                        margin: 0 3.5px;
                        // width: 28.533vw;
                        height: 8.3vw;
                        background-size: 100%;
                    }

                    .apple_btn {
                        // background-image: url(../../assets/images/appstore.png);
                        img{
                            width: 33.067vw;
                            height: 11.467vw;
                        }
                    }

                    .google_btn {
                        // background-image: url(../../assets/images/googleplay.png);
                        img{
                            width: 33.067vw;
                            height: 11.467vw;
                        }
                    }
                }
            }
        }
    }
}