.toast {
    :global {
        .toast-container {
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.5);
            z-index: 1105;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transition: 0.2s cubic-bezier(0.55, 0.55, .2);
            transform: translateZ(0);

            .toast-content {
                background: #fff;
                border-radius: 12px;
                font-size: 14px;
                position: relative;
                max-width: 320px;
                min-width: 200px;

                svg {
                    // margin-right: 10px;
                    width: 44px;
                    display: block;
                    margin: 0 auto 20px;
                }

                .toast-close {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    right: 12px;
                    top: 12px;
                    cursor: pointer;


                    svg {
                        fill: #B0BED4;
                        margin-right: 0;
                        max-width: 100%;
                    }
                }
            }

            .toast-title {
                padding: 20px 30px;
                font-size: 28px;
                font-weight: bold;
                border-bottom: 1px solid rgba(200, 200, 200, 0.15);
            }

            .toast-body {
                padding: 30px 30px;
                overflow: hidden;
                line-height: 1.5;
                text-align: center;

                // svg {
                //     float: left;
                // }
            }

            .toast-footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                border-top: 1px solid rgba(200, 200, 200, 0.15);

                >div {
                    flex-grow: 1;
                    padding: 20px 10px;

                    &:not(:last-child) {
                        border-right: 1px solid rgba(200, 200, 200, 0.15);
                    }
                }

            }
        }

        .popIn {
            animation-name: popIn;
            animation-duration: .2s;
            animation-fill-mode: both;
        }

        @keyframes popIn {
            0% {
                transform: scale3d(0, 0, 0);
                transform-origin: 50% 50%;
                opacity: 0;
            }

            50% {
                animation-timing-function: cubic-bezier(0.55, 0.55, .2);
                transform-origin: 50% 50%;
                transform: scale3d(1.08, 1.08, 1.08);
            }

            100% {
                animation-timing-function: cubic-bezier(0.55, 0.55, .2);
                transform-origin: 50% 50%;
                transform: scale3d(1, 1, 1);
                opacity: 1;
            }
        }


        .popOut {
            animation-name: popOut;
            animation-duration: .2s;
            animation-fill-mode: both;
        }

        @keyframes popOut {
            0% {
                transform: scale3d(1, 1, 1);
                transform-origin: 50% 50%;
                opacity: 1;
            }

            25% {
                animation-timing-function: cubic-bezier(0.55, 0.55, .2);
                transform-origin: 50% 50%;
                transform: scale3d(1.1, 1.1, 1.1);
            }

            75% {
                animation-timing-function: cubic-bezier(0.55, 0.55, .2);
                transform-origin: 50% 50%;
                transform: scale3d(0, 0, 0);
            }

            100% {
                opacity: 0;
            }


        }
    }
}

@media(max-width:1080px) {
    .toast {
        :global {
            .toast-container {
                .toast-content {
                    max-width: 70%;
                }
            }
        }
    }
}